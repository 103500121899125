.App {
  display: flex;
  flex: 1;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
}

@font-face {
  font-family: "rubik-bold";
  src: url("./assets/fonts/Rubik-Bold.ttf");
}
@font-face {
  font-family: "rubik-regular";
  src: url("./assets/fonts/Rubik-Regular.ttf");
}

.rubik-bold {
  font-family: "rubik-bold" !important;
}

.rubik-regular {
  font-family: "rubik-regular" !important;
}
